import React, {useState, useEffect} from 'react'
//import fireDb from "../firebase";
import { initializeApp } from "firebase/app";
import { collection, DocumentSnapshot, getDocs, getFirestore,onSnapshot } from "firebase/firestore";
import { doc, setDoc,updateDoc,deleteDoc,getDoc } from "firebase/firestore";
import Navbar from './NavBar';
import { Link } from 'react-router-dom'
import "./PatientTable.css"
import {Table} from 'react-bootstrap'
import { auth,obj,type1,type2,type3,type4,type5,type6,type7,type8,type9,type10,type11,type12,type13,type14,type15,type16,type17,type18,type19,type20,type21,type22,type23,type24,type25,type26,type27,type28,type29,type30,type31,type32 } from "./fbgen2" 
import { useNavigate,useLocation } from 'react-router-dom';

import { CSVLink } from "react-csv";

function PatientTable()
{ 
  const location = useLocation();
  const [data, setData] = useState([]);
  const [data2, setMe] = useState('');
  const [showColumn, setShowColumn] = useState(true);

  let ent = []
  let data3=''

  let list = [
    "CET01","CET02","CET03","CET04","CET05",
    "CET06","CET07","CET08","CET09","CET10",
    "CET11","CET12","CET13","CET14","CET15",
    "CET16","CET17","CET18","CET19","CET20",
    "CET21","CET22","CET23","CET24","CET25",
    "CET26","CET27","CET28","CET29","CET30",
    "CET31","CET32"
  ]
  useEffect(() => {
    
},[]);

console.log(auth.currentUser.email)

switch(auth.currentUser.email)
{    case type1:
          var colref = collection(obj,"CET02")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET02"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type2:
          var colref = collection(obj,"CET03")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET03"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;
        
        case type3:
          var colref = collection(obj,"CET04")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET04"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type4:
          var colref = collection(obj,"CET05")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET05"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type5:
          var colref = collection(obj,"CET06")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET06"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type6:
          var colref = collection(obj,"CET07")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET07"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type7:
          var colref = collection(obj,"CET08")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET08"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type8:
          var colref = collection(obj,"CET09")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET09"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type9:
          var colref = collection(obj,"CET10")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET10"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type10:
          var colref = collection(obj,"CET11")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET11"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type11:
          var colref = collection(obj,"CET12")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET12"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type12:
          var colref = collection(obj,"CET13")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET13"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type13:
          var colref = collection(obj,"CET14")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET14"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type14:
          var colref = collection(obj,"CET15")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET15"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type15:
          const fetchPromises = list.map((collectionName) => {
            const colref = collection(obj, collectionName);
            return getDocs(colref).then((snapshot) => {
              snapshot.docs.forEach((doc) => {
                data.push({ ...doc.data(), id: doc.id, db: collectionName });
              });
            });
          });
          Promise.all(fetchPromises)
            .then(() => {
              setMe(); // Ensure this is called after all data is fetched
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode, errorMessage);
            });
        break;

        case type16:
          var colref = collection(obj,"CET16")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET16"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type17:
          var colref = collection(obj,"CET17")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET17"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type18:
          var colref = collection(obj,"CET18")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET18"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type19:
          var colref = collection(obj,"CET19")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET19"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type20:
          var colref = collection(obj,"CET20")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET20"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type21:
          var colref = collection(obj,"CET21")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET21"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type22:
          var colref = collection(obj,"CET22")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET22"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type23:
          var colref = collection(obj,"CET23")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET23"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type24:
          var colref = collection(obj,"CET24")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET24"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type25:
          var colref = collection(obj,"CET25")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET25"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type26:
          var colref = collection(obj,"CET26")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET26"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type27:
          var colref = collection(obj,"CET27")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET27"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type28:
          var colref = collection(obj,"CET28")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET28"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type29:
          var colref = collection(obj,"CET29")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET29"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type30:
          var colref = collection(obj,"CET30")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET30"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type31:
          var colref = collection(obj,"CET31")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET31"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;

        case type32:
          var colref = collection(obj,"CET32")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id,db: "CET32"})
                  setMe();
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
        break;
}
  
  const navigate = useNavigate(); 
  const navlink = useNavigate();
  const trial = (e) =>{
    e.preventDefault();
    console.log(data2)
  }

  const onDelete = (id) =>{

    //console.log(data[id].id);
    const r = doc(obj,data[id].db,data[id].id);
    deleteDoc(r);
    navlink('/redirect/');
  }

  const onUpdate = (id) =>{
    navigate(`/LOLO/${data[id].id}/${data[id].db}`);
  }

  const header = [
    {label: "Center Name" , key: "db"},
    {label: "Patient ID" , key: "id"},
    {label: "Technique" , key: "Operation"},
    {label: "Operator Name" , key: "OperatorName"},
    {label: "Date of Procedure" , key: "Date"},
    {label: "Patient Name" , key: "Name"},
    {label: "Age" , key: "Age"},
    {label: "Gender" , key: "Sex"},
    
   /* {label: "BMI" , key: "BMI"},*/
    {label: "DM" , key: "DM"},
    {label: "HTN" , key: "HTN"},
    {label: "Dyslipidemia" , key: "Dyslipidemia"},
    {label: "Smoking" , key: "Smoking"},
    /*{label: "FHCAD" , key: "FHCAD"},*/
    {label: "CKD" , key: "CKD"},
    {label:"PAD", key: "PAD"},
    {label:"Prior PCI", key: "PCI"},
    {label:"Prior CABG", key: "CABG"},
    {label: "Any other Comorbidity" , key: "Comorbidity"},
    {label: "Diagnosis" , key: "Diagnosis"},
    {label: "CSA / ACS" , key: "CSAorACS"},
    {label: "Type of ACS" , key: "TACS"},
    /*{label: "CVA" , key: "CVA"},*/
    /*{label: "PrevCABGorPCI" , key: "PrevCABGorPCI"},*/
    
    
    {label: "EF" , key: "EF"},
    
    
    
    {label: "LM (% stenosis)" , key: "LM"},
    {label: "LAD (% stenosis)" , key: "LAD"},
    {label: "LCx (% stenosis)" , key: "LCx"},
    {label: "RCA (% stenosis)" , key: "RCA"},
    {label: "Medina Class" , key: "median"},
    {label: "SVD / DVD / TVD" , key: "SVDorDVDorTVD"},
    {label: "Syntax Score" , key: "SyntaxScore"},
    {label: "IVUS / OCT" , key: "IVUSorOCT"},
    {label: "MB (LAD / LCx)" , key: "MB"},
    {label: "Route" , key: "Route"},
    {label: "Guide" , key: "Guide"},
    {label: "Plaque Morphology" , key: "Plaque"},
    {label: "MLA" , key: "MLA"},
    {label: "MB-DRD" , key: "mbdrd"},
    {label: "SB-DRD at 15 mm from the ostium" , key: "SBDRD"},
    {label: "SB ostial diameter" , key: "SBOstil"},
    {label: "LM Diameter" , key: "LMDiameter"},
    {label: "Plaque Modification device" , key: "PlaqueMDevice"},
    {label: "MB Balloon Size" , key: "MBBalloon"},
    {label: "MB Balloon pressure" , key: "MBBalloonPressure"},
    {label: "SB dilatation" , key: "SBdilatation"},
    {label: "Stent Name" , key: "StentName"},
    {label: "Stent Size" , key: "StenSize"},
    {label: "POT Balloon Size" , key: "POTBalloonSize"},
    {label: "POT Balloon Pressure" , key: "POTBalloonPressure"},
    {label: "MB Post Dilatation" , key: "MBPostDilatation"},
    {label: "Angiographic SB compromise " , key: "AngiographicSBC"},
    {label: "Chest Pain" , key: "ChestPain"},
    {label: "ECG Changes" , key: "ECGChanges"},
    {label: "TIMI III flow in SB" , key: "TIMIFlow"},
    {label: "SB FFR" , key: "SBFFR"},
    {label: "KBI" , key: "KBI"},
    {label: "MB Balloon Size For KBI" , key: "MBBalloonSizeForKBI"},
    {label: "SB Balloon Size For KBI" , key: "SBBalloonSizeForKBI"},
    {label: "SB Complication After KBI" , key: "SBComplicationAfterKBI"},
    {label: "MB MSA distal to carina" , key: "MBMSA"},
    {label: "LM MSA proximal to carina" , key: "LMMSA"},
    {label: "dLM area" , key: "dLM"},
    {label: "Ostial LAD (MB) area" , key: "ostialLAD"},
    {label: "MB expansion distal to carina" , key: "MBexpansion"},
    {label: "LM expansion proximal to carina" , key: "LMexpansion"},
    {label: "Edge Dissection" , key: "EdgeDissection"},
    {label: "Well Apposed" , key: "WellApposed"},
    {label: "Plaque Prolapse" , key: "PlaqueProlapse"},
    {label: "Number of stent struts across SBO in non-KBI group" , key: "NStentStruts"},
    {label: "Link type / link free carina in non-KBI group" , key: "LinkType"},
    {label: "Remaining number of stent struts in KBI group" , key: "RNStentStruts"},
    {label: "SBO dissection / thrombus in KBI group" , key: "SBODissection"},
    {label: "Document Caudal views on which SBO is left for comparison after one year" , key: "DocumentCaudalViews"},
    {label: "CAG after 1 year SB Restenosis on same caudal view" , key: "CAGSB"},
    {label: "CAG after 1 year MB Restenosis" , key: "CAGMB"},
    {label: "IVUS / OCT after one year" , key: "IVUSorOCTafter"},
    {label: "Epithelialization of hanging stent struts" , key: "Epithelialization"},
    {label: "Cardiac Death (CD)" , key: "cardiacDeath"},
    {label: "Myocardial Infarction (MI)" , key: "Myocardial"},
    {label: "Target Lesion Revascularization  (TLR)" , key: "TargetLesion"},
    {label: "Event Date (Event=CD, MI or TLR)" , key: "EventDate"},
    
    {label:"Stent Thrombosis", key:"StentThrombosis"},
    {label: "Modified Date" , key: "ModifiedDate"},
    {label: "Modified By" , key: "ModifiedBy"}
  ]


  return (
    <div>
      <Navbar/>
      <center>
      <CSVLink data={data} headers={header} filename="data.csv">
        
          <button class="btn1">
          <b>Download As Excel</b>
          </button>
      
    </CSVLink>
    </center>

      <div style={{marginTop: "30px" , overflow:"scroll"}}>
      <Table responsive className="styled-table">
        <thead>
          <tr>
          <th style={{textAlign: "center"}}>Sr. No.</th>
          <th style={{textAlign: "center"}}>Action-Update</th>
          {/*showColumn && (<th style={{textAlign: "center"}}>Action-Delete</th>)*/}
          <th style={{textAlign: "center"}}>Action-Delete</th>
            <th style={{textAlign: "center"}}>Center Name</th>
            <th style={{textAlign: "center"}}>Patient ID</th>
            <th style={{textAlign: "center"}}>Operation</th>
            <th style={{textAlign: "center"}}>Operator Name</th>
            <th style={{textAlign: "center"}}>Date of Procedure</th>
            <th style={{textAlign: "center"}}>Patient Name</th>
            <th style={{textAlign: "center"}}>Age</th>
            <th style={{textAlign: "center"}}>Sex</th>
           {/* <th style={{textAlign: "center"}}>BMI</th> */}
            <th style={{textAlign: "center"}}>DM</th>
            <th style={{textAlign: "center"}}>HTN</th>
            <th style={{textAlign: "center"}}>Dyslipidemia</th>
            <th style={{textAlign: "center"}}>Smoking</th>
            {/*<th style={{textAlign: "center"}}>FHCAD</th>*/}
            <th style={{textAlign: "center"}}>CKD</th>
            <th style={{textAlign: "center"}}>PAD</th>
            <th style={{textAlign: "center"}}>Prior PCI</th>
            <th style={{textAlign: "center"}}>Prior CABG</th>
            <th style={{textAlign: "center"}}>Any other Comorbiditiy</th>
            <th style={{textAlign: "center"}}>Diagnosis</th>
            <th style={{textAlign: "center"}}>ACS or CSA</th>
            <th style={{textAlign: "center"}}>Type of ACS</th>





            {/*<th style={{textAlign: "center"}}>CVA</th>*/}
            {/*<th style={{textAlign: "center"}}>PrevCABGorPCI</th>
            <th style={{textAlign: "center"}}>CSAorACS</th>*/}
            <th style={{textAlign: "center"}}>EF</th>
            
           
            
            <th style={{textAlign: "center"}}>LM</th>
            <th style={{textAlign: "center"}}>LAD</th>
            <th style={{textAlign: "center"}}>LCx</th>
            <th style={{textAlign: "center"}}>RCA</th>
            <th style={{textAlign: "center"}}>Median Class</th>

            <th style={{textAlign: "center"}}>SVD/DVD/TVD</th>
            <th style={{textAlign: "center"}}>Syntax Score</th>
            <th style={{textAlign: "center"}}>IVUS/OCT</th>
            <th style={{textAlign: "center"}}>MB (LAD/LCx)</th>
            <th style={{textAlign: "center"}}>Route</th>
            <th style={{textAlign: "center"}}>Guide</th>
            <th style={{textAlign: "center"}}>Plaque Morphology</th>
            <th style={{textAlign: "center"}}>MLA</th>
            <th style={{textAlign: "center"}}>MB-DRD</th>
            <th style={{textAlign: "center"}}>SB-DRD at 15mm from the ostium</th>
            <th style={{textAlign: "center"}}>SB Ostial Diameter</th>
            <th style={{textAlign: "center"}}>LM Diameter</th>
            <th style={{textAlign: "center"}}>Plaque Modification device</th>
            <th style={{textAlign: "center"}}>MB Balloon Size</th>
            <th style={{textAlign: "center"}}>MB Balloon pressure</th>
            <th style={{textAlign: "center"}}>SB dilatation (Y/N)</th>
            <th style={{textAlign: "center"}}>Stent Name</th>
            <th style={{textAlign: "center"}}>Stent Size</th>
            <th style={{textAlign: "center"}}>POT Balloon Size</th>
            <th style={{textAlign: "center"}}>POT Balloon Pressure</th>
            <th style={{textAlign: "center"}}>MB post-dilatation (Y/N)</th>
            <th style={{textAlign: "center"}}>Angiographic SB compromise (Y/N)</th>
            <th style={{textAlign: "center"}}>Chest Pain (Y/N)</th>
            <th style={{textAlign: "center"}}>ECG Changes (Y/N)</th>
            <th style={{textAlign: "center"}}>TIMI III flow in SB(Y/N)</th>
            <th style={{textAlign: "center"}}>SB FFR(NA if not done)</th>
            <th style={{textAlign: "center"}}>KBI (Y/N)</th>
            <th style={{textAlign: "center"}}>MB Balloon Size For KBI</th>
            <th style={{textAlign: "center"}}>SB Balloon Size For KBI</th>
            <th style={{textAlign: "center"}}>SB Complication After KBI (Y/N)</th>
            <th style={{textAlign: "center"}}>MB MSA distal to carina</th>
            <th style={{textAlign: "center"}}>LM MSA proximal to carina</th>
            <th style={{textAlign: "center"}}>DLM area</th>
            <th style={{textAlign: "center"}}>Ostial LAD (MB) area</th>
            <th style={{textAlign: "center"}}>MB expansion distal to carina</th>
            <th style={{textAlign: "center"}}>LM expansion proximal to carina</th>
            <th style={{textAlign: "center"}}>Edge Dissection (Y/N)</th>
            <th style={{textAlign: "center"}}>Well apposed (Y/N)</th>
            <th style={{textAlign: "center"}}>Plaque Prolapse (Y/N)</th>
            <th style={{textAlign: "center"}}>Number of stent struts across SBO in non-KBI groups</th>
            <th style={{textAlign: "center"}}>Link type/Link free carina in non-KBI group</th>
            <th style={{textAlign: "center"}}>Remaining number of stent struts in KBI groups</th>
            <th style={{textAlign: "center"}}>SBO dissection/thrombus in KBI group (Y/N)</th>
            <th style={{textAlign: "center"}}>Document Caudal views on which SBO is left for comparisom after one year</th>
            <th style={{textAlign: "center"}}>CAG after 1 year SB Restenosis on same caudal view</th>
            <th style={{textAlign: "center"}}>CAG after 1 year MB Restenosis (Y/N)</th>
            <th style={{textAlign: "center"}}>IVUS/OCT after one year (Y/N)</th>
            <th style={{textAlign: "center"}}>Epithelization of hanging stent struts (Y/N)</th>
            <th style={{textAlign: "center"}}>Cardiac Death(CD)(Y/N)</th>
            <th style={{textAlign: "center"}}>Myocardial Infection(MI)(Y/N)</th>
            <th style={{textAlign: "center"}}>Target lesion Revascularization(TLR)(Y/N)</th>
            <th style={{textAlign: "center"}}>Event Date (Event=CD,ML & TLR)</th>
            <th style={{textAlign: "center"}}>Stent Thrombosis</th>
            <th style={{textAlign: "center"}}>Modified Date</th>
            <th style={{textAlign: "center"}}>Modified By</th>
          </tr>
        </thead>
   
        <tbody>
          {
          Object.keys(data).map((id,index) => {
            return(
              <tr key={id}>
                 <th scope="row"> {index + 1} </th>
                <td>
                  <button className='btn btn-edit' onClick={ () => onUpdate(id)}> Update </button>  
                </td>
               <td>
                  <button className='btn btn-edit' onClick={ () => onDelete(id)}> Delete </button>  
                </td> 
                                { /* showColumn && (<td>
                  <button className='btn btn-edit' onClick={ () => onDelete(id)}> Delete </button>  
                </td>) */}

                <td style={{textAlign: "center"}}>{data[id].db}</td>
                <td style={{textAlign: "center"}}>{data[id].id}</td>
                <td style={{textAlign: "center"}}>{data[id].Operation}</td>
                <td style={{textAlign: "center"}}>{data[id].OperatorName}</td>
                <td style={{textAlign: "center"}}>{data[id].Date}</td>
                <td style={{textAlign: "center"}}>{data[id].Name}</td>
                <td style={{textAlign: "center"}}>{data[id].Age}</td>
                <td style={{textAlign: "center"}}>{data[id].Sex}</td>

                {/*<td style={{textAlign: "center"}}>{data[id].BMI}</td>*/}
		            <td style={{textAlign: "center"}}>{data[id].DM}</td>
                <td style={{textAlign: "center"}}>{data[id].HTN}</td>
                <td style={{textAlign: "center"}}>{data[id].Dyslipidemia}</td>
                <td style={{textAlign: "center"}}>{data[id].Smoking}</td>
                {/*<td style={{textAlign: "center"}}>{data[id].FHCAD}</td>*/}
                <td style={{textAlign: "center"}}>{data[id].CKD}</td>
                {/*<td style={{textAlign: "center"}}>{data[id].CKD}</td>*/}
                <td style={{textAlign: "center"}}>{data[id].PAD}</td>
                <td style={{textAlign: "center"}}>{data[id].PCI}</td>
                <td style={{textAlign: "center"}}>{data[id].CABG}</td>
                <td style={{textAlign: "center"}}>{data[id].Comorbidity}</td>
                <td style={{textAlign: "center"}}>{data[id].Diagnosis}</td>
                <td style={{textAlign: "center"}}>{data[id].CSAorACS}</td>
                <td style={{textAlign: "center"}}>{data[id].TACS}</td>



               {/* <td style={{textAlign: "center"}}>{data[id].PrevCABGorPCI}</td>*/}
               
                
                <td style={{textAlign: "center"}}>{data[id].EF}</td>
                
                
                
                <td style={{textAlign: "center"}}>{data[id].LM}</td>
                <td style={{textAlign: "center"}}>{data[id].LAD}</td>
                <td style={{textAlign: "center"}}>{data[id].LCx}</td>
                <td style={{textAlign: "center"}}>{data[id].RCA}</td>
                <td style={{textAlign: "center"}}>{data[id].median}</td>
                <td style={{textAlign: "center"}}>{data[id].SVDorDVDorTVD}</td>
                <td style={{textAlign: "center"}}>{data[id].SyntaxScore}</td>
                <td style={{textAlign: "center"}}>{data[id].IVUSorOCT}</td>
                <td style={{textAlign: "center"}}>{data[id].MB}</td>
                <td style={{textAlign: "center"}}>{data[id].Route}</td>
                <td style={{textAlign: "center"}}>{data[id].Guide}</td>
                <td style={{textAlign: "center"}}>{data[id].Plaque}</td>
                <td style={{textAlign: "center"}}>{data[id].MLA}</td>
                <td style={{textAlign: "center"}}>{data[id].mbdrd}</td>
                <td style={{textAlign: "center"}}>{data[id].SBDRD}</td>
                <td style={{textAlign: "center"}}>{data[id].SBOstil}</td>
                <td style={{textAlign: "center"}}>{data[id].LMDiameter}</td>
                <td style={{textAlign: "center"}}>{data[id].PlaqueMDevice}</td>
                <td style={{textAlign: "center"}}>{data[id].MBBalloon}</td>
                <td style={{textAlign: "center"}}>{data[id].MBBalloonPressure}</td>
                <td style={{textAlign: "center"}}>{data[id].SBdilatation}</td>
                <td style={{textAlign: "center"}}>{data[id].StentName}</td>
                <td style={{textAlign: "center"}}>{data[id].StenSize}</td>
                <td style={{textAlign: "center"}}>{data[id].POTBalloonSize}</td>
                <td style={{textAlign: "center"}}>{data[id].POTBalloonPressure}</td>
                <td style={{textAlign: "center"}}>{data[id].MBPostDilatation}</td>
                <td style={{textAlign: "center"}}>{data[id].AngiographicSBC}</td>
                <td style={{textAlign: "center"}}>{data[id].ChestPain}</td>
                <td style={{textAlign: "center"}}>{data[id].ECGChanges}</td>
                <td style={{textAlign: "center"}}>{data[id].TIMIFlow}</td>
                <td style={{textAlign: "center"}}>{data[id].SBFFR}</td>
                <td style={{textAlign: "center"}}>{data[id].KBI}</td>
                <td style={{textAlign: "center"}}>{data[id].MBBalloonSizeForKBI}</td>
                <td style={{textAlign: "center"}}>{data[id].SBBalloonSizeForKBI}</td>
                <td style={{textAlign: "center"}}>{data[id].SBComplicationAfterKBI}</td>
                <td style={{textAlign: "center"}}>{data[id].MBMSA}</td>
                <td style={{textAlign: "center"}}>{data[id].LMMSA}</td>
                <td style={{textAlign: "center"}}>{data[id].dLM}</td>
                <td style={{textAlign: "center"}}>{data[id].ostialLAD}</td>
                <td style={{textAlign: "center"}}>{data[id].MBexpansion}</td>
                <td style={{textAlign: "center"}}>{data[id].LMexpansion}</td>
                <td style={{textAlign: "center"}}>{data[id].EdgeDissection}</td>
                <td style={{textAlign: "center"}}>{data[id].WellApposed}</td>
                <td style={{textAlign: "center"}}>{data[id].PlaqueProlapse}</td>
                <td style={{textAlign: "center"}}>{data[id].NStentStruts}</td>
                <td style={{textAlign: "center"}}>{data[id].LinkType}</td>

                <td style={{textAlign: "center"}}>{data[id].RNStentStruts}</td>
                <td style={{textAlign: "center"}}>{data[id].SBODissection}</td>
                <td style={{textAlign: "center"}}>{data[id].DocumentCaudalViews}</td>
                <td style={{textAlign: "center"}}>{data[id].CAGSB}</td>
                <td style={{textAlign: "center"}}>{data[id].CAGMB}</td>
                <td style={{textAlign: "center"}}>{data[id].IVUSorOCTafter}</td>
                <td style={{textAlign: "center"}}>{data[id].Epithelialization}</td>
                <td style={{textAlign: "center"}}>{data[id].cardiacDeath}</td>
                <td style={{textAlign: "center"}}>{data[id].Myocardial}</td>
                <td style={{textAlign: "center"}}>{data[id].TargetLesion}</td>
                <td style={{textAlign: "center"}}>{data[id].EventDate}</td>
                <td style={{textAlign: "center"}}>{data[id].StentThrombosis}</td>
                <td style={{textAlign: "center"}}>{data[id].ModifiedDate}</td>
                <td style={{textAlign: "center"}}>{data[id].ModifiedBy}</td>
              </tr>
            )
          })}
        </tbody>

      </Table>
    </div>
    </div>
    
  )
}

export default PatientTable